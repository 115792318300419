<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="등록 간병인 목록" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-text-field v-model.trim="filterName" label="이름/필터" outlined dense :required="[]" hide-details="auto" />
      </v-col>
      <v-col cols="auto">
        <v-btn class="mx-0" color="primary" :to="`/carers/add`">
          <v-icon>add</v-icon>
          <span class="pl-2">등록</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="tbl elevation-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="item in headers" :key="item.text" :class="`text-${item.align}`">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender :is-loading="isLoading" :cols="headers.length" :items-length="items.length" />
            <tbody v-if="!isLoading">
              <tr v-for="(item, i) in items" :key="`patient-item-${item.extra_info.idx}`">
                <td>
                  <router-link :to="`/carers/${item.extra_info.idx}`">
                    {{ item.extra_info.idx }}
                  </router-link>
                </td>
                <td class="py-2">
                  <v-chip
                    small
                    outlined
                    class="mr-2 ml-2 justify-center"
                    :color="item.basic_info.gender === 'male' ? 'blue' : 'red'"
                  >
                    {{ item.basic_info.age }}세, {{ item.basic_info.getGender() }}
                  </v-chip>
                  <router-link :to="`/carers/${item.extra_info.idx}`">
                    {{ item.basic_info.name }}
                  </router-link>
                </td>
                <td class="py-2 text-center">
                  <Promised :promise="item.extra_info.isPaid()">
                    <template v-slot:combined="{ data }">
                      {{ data }}
                    </template>
                  </Promised>
                </td>
                <td class="text-center pr-2">
                  {{ countMatches[i] }}
                </td>
                <td class="text-center pr-2">
                  {{ countApplicants[i] }}
                </td>
                <td class="text-center">
                  {{ item.extra_info.career }}
                </td>
                <td class="text-center">
                  {{ item.extra_info.license || 'N/A' }}
                </td>
                <td class="py-2">
                  <v-chip outlined small>
                    <Promised :promise="item.extra_info.getAddress()">
                      <template v-slot:combined="{ data }">
                        {{ data }}
                      </template>
                    </Promised>
                  </v-chip>
                </td>
                <td class="py-2">
                  <v-chip label small class="mr-2">
                    {{ item.extra_info.office_hour }}
                  </v-chip>
                  <v-chip label small class="mr-2">
                    {{ item.extra_info.desired_salary }}
                  </v-chip>
                </td>
                <td class="text-center">
                  {{ item.extra_info.updated_at | dayjsLocalDatetime }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="model.pagination" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import paginationLib from '../_elements/pagination/paginationLib'
import { debounce } from 'debounce'
import Config from '@/config'
import axios from 'axios'
import PaginationComponent from '../_elements/pagination/Pagination'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import CarerList from './model/carerList'
// import Api from './model/api'

export default {
  components: {
    PageTitleComponent,
    PaginationComponent,
    TbodyPreRender
  },
  data() {
    return {
      model: new CarerList(),
      isLoading: false,
      query: '',
      items: [],
      headers: [
        { text: 'idx', align: 'left' },
        { text: '프로필', align: 'center' },
        { text: '결제유무', align: 'center' },
        { text: '매칭횟수', align: 'center' },
        { text: '지원횟수', align: 'center' },
        { text: '경력', align: 'center' },
        { text: '자격증', align: 'center' },
        { text: '활동지역', align: 'center' },
        { text: '희망조건', align: 'center' },
        { text: '최근 수정일', align: 'center' }
      ],
      countMatches: [],
      countApplicants: [],
      filterName: this.$route.query.name
    }
  },
  watch: {
    filterName: {
      handler: debounce(function(v) {
        this.$router.push({ query: { page: 1, name: v } })
      }, 1000)
    }
  },
  created() {
    const params = this.$route.query
    if (!params.page) {
      this.$router.push({ query: { page: 1 } }, () => {})
    } else {
      this.query = params.query || ''
      this.model.pagination.page = parseInt(params.page, 10) || 1
      this.getList()
    }
  },
  methods: {
    async getList() {
      this.isLoading = true
      const { items, pagination } = await this.model.getList(this.filterName)
      if (items) {
        this.items = items
        this.model.pagination = { ...pagination } // vue 2 not catch deep object
      }
      this.isLoading = false

      this.countMatches = await this.getCountMatches()
      this.countApplicants = await this.getCountApplicants()
    },
    async getCountMatches() {
      return axios
        .get(`${Config.Api.Service2.url}/caregiver/count-match`, {
          params: {
            ids: JSON.stringify(this.items.map(item => item.extra_info.idx))
          }
        })
        .then(({ data }) => data)
    },
    async getCountApplicants() {
      return axios
        .get(`${Config.Api.Service2.url}/caregiver/count-apply`, {
          params: {
            ids: JSON.stringify(this.items.map(item => item.extra_info.idx))
          }
        })
        .then(({ data }) => data)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
